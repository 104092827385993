<template>
  <div class="page-wrapper">
    <header class="navbar">
      <img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" />
    </header>
    <h1 class="big-title">Showadz terms and coditions</h1>

    <p>
      In using this Website and our Showadz online platform and services (the Service) you are deemed to have read and agreed to the following terms and
      conditions:
    </p>
    <p>
      The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: Client, You and
      Your refers to you, the person accessing this Website and accepting Showadz Terms and Conditions. The Company, Ourselves, We and Us, refers to
      Showadz Inc. Party, Parties, or Us, refers to both the Client and ourselves, or either the Client or ourselves. Any use of the above terminology or
      other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.
    </p>
    <p>
      If You are using the Service on behalf of another organization or entity, you represent and warrant to Showadz that you have the necessary authority
      to agree to these terms and conditions on behalf of such third-party. If you sign up for a Showadz account or otherwise use the Service on behalf of
      any other individual, you represent and warrant to Showadz that you have the necessary consents from such individual to sign up on their behalf and
      provide any personal information of such individual.
    </p>
    <p>
      If these terms and conditions are considered an offer by Showadz, acceptance is expressly limited to these terms. The Website is available only to
      individuals who are at least 13 years old.
    </p>
    <h2 class="small-title">Privacy Statement</h2>
    <p>
      We are committed to protecting your privacy. Authorized employees within the company on a need to know basis only use any information collected from
      individual customers. We constantly review our systems and data to ensure the best possible service to our customers. It is the Clients
      responsibility to inform us if it has any end users in the European Economic Area. If so, Client will be required to enter into a Data Processing
      Agreement with Showadz in order to use the Showadz Service.
    </p>
    <h2 class="small-title">Confidentiality</h2>
    <p>
      Client records are regarded as confidential and therefore will not be divulged to any third party, other than [our manufacturer/supplier(s) and if
      legally required to do so to the appropriate authorities. Clients have the right to request sight of, and copies of any and all Client Records we
      keep, on the proviso that we are given reasonable notice of such a request. Clients are requested to retain copies of any literature issued in
      relation to the provision of our services. Where appropriate, we shall issue Clients with appropriate written information, handouts or copies of
      records as part of an agreed contract, for the benefit of both parties.
    </p>
    <p>
      We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by
      this Company will only be in connection with the provision of agreed services and products.
    </p>
    <h2 class="small-title">End User License</h2>
    <p>
      Except for Client content, this Website, Service and the information and materials that they contains, are the property of Showadz and its licensors,
      and are protected from unauthorized copying and dissemination by copyright law, trademark law, and other intellectual property laws. Subject to the
      terms of this Agreement, Showadz grants you a non-transferable, non-exclusive, license to (a) use the Website and Service for your internal business
      purposes (the License). Nothing in the Terms gives you a right to use the Showadz names, trademarks, logos, domain names, and other distinctive brand
      features without our prior written consent. Any future release, update, or other addition to functionality of the Website or Service shall be subject
      to the terms of these Terms.
    </p>
    <h2 class="small-title">Disclaimer, Exclusions and Limitations</h2>
    <p>
      The information on this web site is provided on an as is basis. To the fullest extent permitted by law, this Company excludes all representations,
      warranties and conditions relating to this Website and Service and its contents or which is or may be provided by any affiliates or any other third
      party, including in relation to any inaccuracies or omissions in this Website and/or the Companys literature; and excludes all liability for damages
      arising out of or in connection with your use of this Website. This includes, without limitation, direct loss, loss of business or profits (whether
      or not the loss of such profits was foreseeable, arose in the normal course of things or you have advised this Company of the possibility of such
      potential loss), damage caused to your computer, computer software, systems and programs and the data thereon or any other direct or indirect,
      consequential and incidental damages. This Company does not however exclude liability for death or personal injury caused by its negligence. The
      above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are affected.
    </p>
    <h2 class="small-title">Payment</h2>
    <p>The client agrees to pay Showadz according to the selected plans. Paid account fees are not refundable.</p>
    <h2 class="small-title">Subscription Renewal</h2>
    <p>
      Unless otherwise set forth in an applicable Order Form, the Subscription Term will automatically renew for a subsequent term, unless either party
      notifies the other party (in writing) of its intent not to renew, at least 30 days prior to the end of plan
    </p>
    <p>
      A party may terminate the Order Form with written notice if the other party breaches a material term of these Terms and fails to correct the breach
      within 30 days following written notice specifying the breach. If Customer terminates the Order Form for cause, Showadz will refund the unused
      portion of the Subscription Fees that Customer had paid for the Showadz Services for the remainder of the Subscription Term.
    </p>
    <h2 class="small-title">Termination of Agreements and Refunds Policy</h2>
    <p>
      Both the Client and ourselves have the right to terminate any Services Agreement for any reason, including the ending of services that are already
      underway. No refunds shall be offered, where a Service is deemed to have begun and is, for all intents and purposes, underway. If you wish to
      terminate this Agreement or your Showadz account (if you have one), you may simply discontinue using the Website and inform your Account Manager.
      Notwithstanding the foregoing, if you have a paid account, such account can only be terminated by Showadz if you materially breach this Agreement and
      fail to cure such breach within thirty (30) days from Showadzs notice to you thereof; provided that, Showadz can terminate the Website immediately as
      part of a general shut down of our service.
    </p>
    <h2 class="small-title">Log Files</h2>
    <p>
      We use IP addresses to analyse trends, administer the site, track users movement, and gather broad demographic information for aggregate use. IP may
      be linked to Personal Information. Additionally, for systems administration, detecting usage patterns and troubleshooting purposes, our web servers
      automatically log standard access information including browser type, access times/open mail, URL requested, and referral URL. This information may
      be linked to other Personal Information. Any individually identifiable information related to this data will never be used in any way different to
      that stated above without your explicit permission. Where any IP or log information constitutes Personal Information, it is treated in accordance
      with this Privacy Policy.
    </p>
    <h2 class="small-title">Cookies</h2>
    <p>
      Like most interactive web sites this Companys Website uses cookies to enable us to retrieve user details for each visit. Cookies are used in some
      areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate partners may also use
      cookies. For more information on how cookies are used, please refer to our Privacy Policy.
    </p>
    <h2 class="small-title">Links to this website</h2>
    <p>
      You may not create a link to any page of this Website without our prior written consent. If you do create a link to a page of this Website you do so
      at your own risk and the exclusions and limitations set out above will apply to your use of this website by linking to it.
    </p>
    <h2 class="small-title">Links from this website</h2>
    <p>
      We do not monitor or review the content of other partys websites which are linked to from this website. Opinions expressed or material appearing on
      such websites are not necessarily shared or endorsed by us and should not be regarded as the publisher of such opinions or material. Please be aware
      that we are not responsible for the privacy practices, or content, of these sites. We encourage our users to be aware when they leave our site & to
      read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this site or accessed
      through this site yourself, before disclosing any personal information to them. This Company will not accept any responsibility for any loss or
      damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.
    </p>
    <h2 class="small-title">Copyright Notice</h2>
    <p>You represent and warrant that content created for or used in performance of the Services:</p>
    <ul>
      <li>
        will not infringe upon or misappropriate any copyright, patent, trademark, trade secret, or other intellectual property right or proprietary right
        or right of publicity or privacy of any person or entity;
      </li>
      <li>will not violate any law or regulation;</li>
      <li>will not be defamatory or libellous;</li>
      <li>will not be obscene or contain child pornography;</li>
      <li>will not contain the development, design, manufacture or production of missiles, or nuclear, chemical or biological weapons;</li>
      <li>will not contain material linked to terrorist activities;</li>
      <li>will not include incomplete, false or inaccurate information about Client or any other individual; and</li>
      <li>
        will not contain any viruses or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously
        intercept or expropriate any system, data or personal information.
      </li>
    </ul>
    <h2 class="small-title">Support</h2>
    <p>
      During the Subscription Term, Showadz will provide technical support for the Showadz Services during regular business hours. Showadz will use
      commercially reasonable efforts to make the Showadz Services available 99.9% of the time per month (Uptime Availability), except for any scheduled or
      emergency maintenance or for any unavailability as a result of system failures or circumstances outside or beyond the reasonable control of Showadz
      (Service Showadz will use commercially reasonable efforts to maintain the Showadz Application in a manner which minimizes Service Interruptions.
      Showadz will inform Customer (by email or other means) of any significant Service Interruptions (greater than 1 hour) and will use commercially
      reasonable efforts to restore the affected Showadz Services as soon as practicable.
    </p>
    <h2 class="small-title">Communication</h2>
    <p>
      We have several different email addresses for different queries. These, & other contact information, can be found on our Contact Us link on our
      Website or via Company literature or via the Companys stated telephone, facsimile or mobile telephone numbers.
    </p>
    <p>This company is registered in Québec, registered 776 rue labelle, Saint-Jérôme QC Canada</p>
    <h2 class="small-title">Force Majeure</h2>
    <p>
      Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control
      of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or
      military authority, uprising, earthquake, flood or any other natural or man made eventuality outside of our control, which causes the termination of
      an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the
      other Party of the same and shall use all reasonable endeavours to comply with the terms and conditions of any Agreement contained herein.
    </p>
    <h2 class="small-title">Indemnity</h2>
    <p>
      You shall indemnify and hold harmless Company from and against any and all losses, damages, settlements, liabilities, costs, charges, assessments,
      and expenses, as well as third party claims and causes of action, including, without limitation, attorneys fees, arising out of any breach by
      Contractor of this Agreement or by Contractors breach of any intellectual property rights of a third party. Contractor shall provide Company with
      such assistance, without charge, as Company may request in connection with any such defense, including, without limitation, providing Company with
      such information, documents, records, and reasonable access to you, as Company deems necessary. Contractor shall not settle any third party claim or
      waive any defense without prior written consent of Company.
    </p>
    <h2 class="small-title">Notification of Changes</h2>
    <p>
      The Company reserves the right to change these conditions from time to time as it sees fit and your continued use of the site will signify your
      acceptance of any adjustment to these terms. If there are any changes to our privacy policy, we will announce that these changes have been made on
      our homepage and on other key pages on our site. If there are any changes in how we use our site customers Personally Identifiable Information,
      notification by e-mail or postal mail will be made to those affected by this change. Any changes to our privacy policy will be posted on our Website
      30 days prior to these changes taking place. You are therefore advised to re-read this statement on a regular basis
    </p>
    <h2 class="small-title">Obligations Surviving Termination of this Agreement</h2>
    <p>
      All obligations to preserve the Companys Confidential Information, Intellectual Property and other warranties and representations set forth herein
      shall survive the termination of this Agreement.
    </p>
    <h2 class="small-title">Entire Agreement</h2>
    <p>
      This Agreement, together with the Confidentiality and Proprietary Information Agreement, represents the entire agreement between the parties and the
      provisions of this Agreement shall supersede all prior oral and written commitments, contracts and understandings with respect to the subject matter
      of this Agreement. This Agreement may be amended only by mutual written agreement of the party.
    </p>
    <h2 class="small-title">Governing Law and Principles of Construction.</h2>
    <p>
      This Agreement shall be governed and construed in accordance with the laws of Canada. If any provision in this Agreement is declared illegal or
      unenforceable, the provision will become void, leaving the remainder of this Agreement in full force and effect.
    </p>
    <p>
      These terms and conditions form part of the Agreement between the Client and ourselves. Your accessing of this Website and/or undertaking of a
      booking or Agreement indicates your understanding, agreement to and acceptance, of the Disclaimer Notice and the full Terms and Conditions contained
      herein. Your statutory Consumer Rights are unaffected.
    </p>
  </div>
</template>

<script>
export default {
  head: function () {
    return {
      title: {
        inner: this.currentProduct ? this.currentProduct.name : 'Privacy Policy',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} article details page`,
          id: 'desc',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 50px;
  background-color: #fff;

  .logo {
    width: 145px;
    display: block;
  }
}

.page-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5vw 0;

  h1 {
    color: $dark-blue;
    margin-bottom: 20px;
  }

  h2 {
    color: $dark-blue;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .small-title {
    font-size: 16px;
  }

  p {
    color: $dark-grey;
    margin-bottom: 15px;
  }

  ul li {
    color: $dark-grey;
  }
}
</style>
