<template>
  <div class="page-wrapper">
    <header class="navbar">
      <img alt="Showadz logo" class="logo" src="@/assets/img/showadz-logo.svg" />
      <div class="right">
        <p class="regular-text">Already have an account?</p>
        <router-link to="/" class="bt">Login</router-link>
      </div>
    </header>

    <section class="main-wrap">
      <div class="left">
        <h2 class="big-title">Receipt summary</h2>
        <div class="row">
          <p>$40 x 12 months</p>
          <p>$480 CAD</p>
        </div>
        <div class="row">
          <p>TPS (5%)</p>
          <p>$24 CAD</p>
        </div>
        <div class="row">
          <p>TVQ (9.975%)</p>
          <p>$47.88 CAD</p>
        </div>
        <div class="row big">
          <p>Total</p>
          <p>$551.88 CAD</p>
        </div>
      </div>
      <div class="right">
        <h1 class="big-title">Payment informations</h1>
        <form action="" class="form-wrap">
          <div class="input-label-wrap full" :class="{ filled: card.name }">
            <input v-model="card.name" type="text" class="label-focus" required />
            <p class="label">Name on credit card</p>
          </div>

          <div class="input-label-wrap full" :class="{ filled: card.number }">
            <input v-model="card.number" type="text" class="label-focus" required />
            <p class="label">Credit card number</p>
          </div>

          <div class="input-label-wrap half" :class="{ filled: card.month }">
            <select v-model="card.month" class="label-focus">
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <p class="label">Exp. Month</p>
          </div>

          <div class="input-label-wrap half" :class="{ filled: card.year }">
            <select v-model="card.year" class="label-focus">
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
            </select>
            <p class="label">Exp. Year</p>
          </div>

          <div class="input-label-wrap full" :class="{ filled: card.cvc }">
            <input v-model="card.cvc" type="text" class="label-focus" required />
            <p class="label">CVC</p>
          </div>

          <div class="bt-wrap">
            <div class="bt bt-text">
              <p>Buy your plan</p>
              <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  head: function () {
    return {
      title: {
        inner: this.currentProduct ? this.currentProduct.name : 'Create an account',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} article details page`,
          id: 'desc',
        },
      ],
    }
  },
  data() {
    return {
      card: {
        name: '',
        number: '',
        month: '',
        year: '',
        cvc: '',
        isArchived: false,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/forms.scss';
@import '@/theme/button.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 50px;
  background-color: #fff;

  .logo {
    width: 145px;
    display: block;
  }

  .right {
    display: flex;
    align-items: center;

    .regular-text {
      margin-bottom: 0;
      margin-right: 10px;
    }

    .bt {
      min-width: 0;
      height: auto;
      padding: 12px 20px 12px 20px;
    }
  }
}

.main-wrap {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  min-width: 1000px;

  .big-title {
    font-size: 26px;
    margin-bottom: 20px;
    color: $dark-blue;
  }

  .left {
    width: 49%;
    background-color: #fff;
    padding: 50px;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      p {
        font-weight: 400;
        color: $dark-grey;
        font-size: 14px;
      }

      &.big {
        p {
          color: $dark-blue;
          font-size: 20px;
          margin-top: 180px;
        }
      }
    }
  }

  .right {
    width: 49%;
    background-color: #fff;
    padding: 50px;
  }
}

.form-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .input-label-wrap {
    margin-bottom: 5px;

    &.full {
      width: 100%;
    }
    &.half {
      width: calc(50% - 5px);
    }
    .label {
      font-size: 14px;
      color: $dark-grey;
    }
    &.filled {
      .label {
        font-size: 12px;
        transform: translate(15px, -120%);
        color: #4628ff;
      }
    }
  }

  .bt-wrap {
    display: flex;
    justify-content: center;
  }

  .bt-text {
    text-align: center;
    margin-top: 15px;
    display: inline-block;
    p {
      color: #fff;
      display: inline-block;
    }
  }
}
</style>
